<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      adminlist: [],
      adminsearch: "",
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      block: false,
      systemtype: 0,
      checkoutloading: false,
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("admin.temporary.text");
    that.items = [
      {
        text: that.$t("menuitems.user.text"),
        href: "/user/list",
      },
      {
        text: that.$t("admin.temporary.text"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (that.userinfo.topuid >= 1) {
      that.block = true;
    }
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    that.getadminlist();

    that.websocket.ws.onmessage = function (res) {
      let wsdata = JSON.parse(res.data);
      if (wsdata.action === "readcard") {
        if (that.cardhex) {
        } else {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "finduser",
                hex: wsdata.uid,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              let res = response.data;
              if (res.status == 200) {
                that.$message({
                  message: that.$t("global.ws.finduser"),
                  type: "success",
                });
                that.$router.push({
                  path: "/user/detail",
                  query: { id: res.data.id },
                });
              } else {
                that.cardhex = wsdata.uid;
                if (!that.bindcardhex && that.bindcarduid) {
                  that
                    .$confirm(
                      that.$t("global.ws.userbindcard"),
                      that.$t("global.delete.sureyouaction"),
                      {
                        confirmButtonText: that.$t("global.button.ok"),
                        cancelButtonText: that.$t("global.button.cancel"),
                        type: "warning",
                      }
                    )
                    .then(() => {
                      that.$axios
                        .post(
                          that.apiuri,
                          {
                            action: "bindnewcard",
                            hex: that.cardhex,
                            uid: that.bindcarduid,
                          },
                          {
                            headers: { Openid: that.userinfo.openid },
                          }
                        )
                        .then(function (response) {
                          that.loading = false;
                          if (response.data.status == 200) {
                            that.$message({
                              message: that.$t("global.ws.bindsuccess"),
                              type: "success",
                            });
                            that.$router.push({
                              path: "/user/list",
                            });
                          } else if (response.data.status == 403) {
                            that.$message({
                              message: that.$t("global.delete.auth"),
                              type: "warning",
                            });
                          } else {
                            that.$message.error(that.$t("global.delete.500"));
                          }
                          that.getadminlist();
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                    })
                    .catch(() => {});
                } else {
                  that.$router.push({
                    path: "/user/add",
                    query: { id: wsdata.uid },
                  });
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
      if (wsdata.action === "notice") {
        wsdata["time"] = Date.parse(new Date());
        wsdata["read"] = 0;
        that.noticelist.unshift(wsdata);
        localStorage.setItem("notice", JSON.stringify(that.noticelist));

        if (wsdata.type2 === "device") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.device", { device: wsdata.edid }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
          });
        } else if (wsdata.type2 === "service") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.service", {
                service: that.$t("global.notice.server." + wsdata.service),
              }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
            duration: 0,
          });
        }
      } else if (wsdata.type2 === "stock") {
        that.$notify({
          title: that.$t("global.notice." + wsdata.status),
          message: that.$t("global.notice.stock", {
            edid: wsdata.edid,
            stock: wsdata.stock,
          }),
          type: wsdata.status,
        });
      } else if (wsdata.type2 === "sensor") {
        that.$notify({
          title: that.$t("global.notice." + wsdata.status),
          message: that.$t("global.notice.sensor", {
            edid: wsdata.edid,
            num: wsdata.num,
          }),
          type: wsdata.status,
        });
      }
    };
    that.getsysteminfo();
    if (that.$route.query.hex) {
      that.adminsearch = that.$route.query.hex;
    }
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getadminlist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "gettempuser",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.adminlist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteadmin(id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.delete.deletetempusertip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.loading = true;
          that.$axios
            .post(
              that.apiuri,
              {
                action: "checkout",
                hex: id,
                status: 0,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.getadminlist();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("admin.temporary.delete403"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    gettemporder() {
      var that = this;
    },
    removefromgroup(uid, idx) {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "rmusergroup",
            uid: uid,
            group: idx,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.getadminlist();
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkout(uid, idx, status) {
      var that = this;

      that
        .$confirm(
          that.$t("device.order.checkouttip"),
          that.$t("global.waring"),
          {
            confirmButtonText: that.$t("device.button.checkout"),
            cancelButtonText: that.$t("device.button.notcheckout"),
            type: "warning",
          }
        )
        .then(() => {
          that.checkoutloading = true;
          that.$axios
            .post(
              that.apiuri,
              {
                action: "checkout",
                hex: uid,
                group: idx,
                status: status,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.checkoutloading = false;
              that.getadminlist();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
  },
  computed: {
    searchinadminlist() {
      var datas = this.adminlist;
      if (this.adminsearch) {
        datas = datas.filter((value) => {
          return (
            !this.adminsearch ||
            value.remark
              .toLowerCase()
              .includes(this.adminsearch.trim().toLowerCase()) ||
            !this.adminsearch ||
            value.hex.includes(this.adminsearch.trim())
          );
        });
      }
      return datas;
    },
  },
  filters: {
    formatTimer: function (value) {
      value = value * 1000;
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!block">
            <div class="row col-md-12">
              <input
                type="search"
                v-model="adminsearch"
                class="form-control"
                :placeholder="$t('user.list.search')"
              />
            </div>
            <div class="row col-md-12">
              <el-table :data="searchinadminlist" style="width: 100%">
                <el-table-column
                  prop="remark"
                  :label="$t('admin.lists.username')"
                  width="100"
                >
                </el-table-column>
                <el-table-column prop="phone" :label="$t('admin.lists.phone')" width="180">
                  <template slot-scope="scope">
                    <span v-if="scope.row.phone">{{ scope.row.phone }}</span>
                    <span v-else>{{ $t("admin.lists.nophone") }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="cardno"
                  :label="$t('user.temporary.active')"
                  width="180"
                >
                  <template slot-scope="scope">
                    {{ scope.row.time | formatTimer }}
                  </template>
                </el-table-column>
                <el-table-column prop="hex" :label="$t('user.list.membercard')" width="130">
                </el-table-column>
                <el-table-column
                  prop="amount"
                  :label="$t('user.temporary.consumption')"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="total"
                  :label="$t('user.temporary.consumptionvalue')"
                  width="180"
                >
                </el-table-column>
                <el-table-column :label="$t('device.lists.action')">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      round
                      size="mini"
                      @click="checkout(scope.row.hex, scope.row.group, 0)"
                      v-loading="checkoutloading"
                      >{{ $t("device.button.checkout") }}</el-button
                    >
                    <el-button
                      type="success"
                      round
                      size="mini"
                      @click="checkout(scope.row.hex, scope.row.group, 1)"
                      v-loading="checkoutloading"
                      >{{ $t("device.button.checkout") }}</el-button
                    >
                    <el-button
                      type="warning"
                      round
                      size="mini"
                      v-if="scope.row.group > 0"
                      @click="removefromgroup(scope.row.id, scope.row.group)"
                      >{{ $t("user.group.remove") }}</el-button
                    >
                    <el-button
                      type="danger"
                      round
                      size="mini"
                      @click="deleteadmin(scope.row.hex)"
                      >{{ $t("device.lists.delete") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="card-body" v-else>
            <el-result
              icon="error"
              :title="$t('global.error')"
              :subTitle="$t('global.text.nopermission')"
            >
              <template slot="extra">
                <el-button type="primary" size="medium">返回</el-button>
              </template>
            </el-result>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>